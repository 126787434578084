.sidebar
    height: 100%
    overflow-y: scroll
    text-align: left

.sidebar-header
    height: 50px
    width: 100%
    display: flex
    justify-content: space-around

    button
        width: 100%
        @apply font-heading text-gray-400

        &.active
            @apply text-blue-900
            @apply border-b-4 rounded-sm border-blue-900
        
        &:hover
            @apply text-blue-900
            transition: ease-in-out 0.1s