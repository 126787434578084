@media only screen and (max-width: 1200px)
    main
        display: grid
        grid-template-columns: 1fr
        grid-template-rows: 1fr 1fr

    .map
        padding: 0.75rem
        border-radius: 0
        @apply border-t border-gray-100

    .listings-label
        padding: 1rem 0