.App
    height: 100vh
    display: grid
    grid-template-rows: auto 1fr
    grid-template-areas: 'header' 'content' 'footer'

main
    height: 100%
    display: grid
    grid-template-columns: 500px 1fr
    overflow: hidden

.sidebar
    height: 100%
    overflow-y: scroll

.map
    height: 100%
    width: 100%
    padding: 2rem 2rem
    -webkit-border-radius: 24px
    -moz-border-radius: 24px
    border-radius: 24px

.wrapper
    max-width: 95%
    margin: 0 auto