@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Merriweather:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
.btn-base {
  @apply font-heading text-sm rounded-md shadow-sm py-2 px-3;
}
.btn-base.blue {
  @apply bg-blue-700 text-white;
}
.btn-base.blue:hover {
  @apply bg-blue-600;
  transition: ease-in-out 0.2s;
}
.btn-base.red {
  @apply bg-red-700 text-white;
}
.btn-base.red:hover {
  @apply bg-red-600;
  transition: ease-in-out 0.2s;
}

.listings-container {
  margin: 0 2rem;
}

.listings-label {
  padding: 2rem 0;
  @apply font-heading font-bold text-2xl text-blue-900;
}

.listing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 100%;
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
  @apply bg-white border-2 border-gray-200 shadow-sm;
}

.listing:hover {
  transition: 0.2s;
  @apply border-blue-900;
}

.listing-header,
.listing-content,
.listing-footer {
  margin: 0.5rem 0;
}

.listing-address {
  @apply font-heading font-medium text-base;
}

.listing-date {
  @apply font-content font-normal text-sm text-gray-500;
}

.listing-time {
  @apply font-content font-normal text-sm text-gray-500;
}

.listing-description {
  @apply font-content font-normal text-sm text-gray-500;
}

.listing-viewed-label {
  padding: 0.5rem;
  @apply font-content font-normal text-sm;
}

.logo {
  max-width: 125px;
}

.map {
  @apply w-screen h-full;
}

.map-marker {
  @apply text-4xl;
  @apply text-rose-700;
  width: 2rem;
  height: 3rem;
  transform: translate(-50%, -75%);
}
.map-marker :hover {
  @apply cursor-pointer;
  @apply text-rose-600;
  transition: 0.1s;
}

.details-modal {
  @apply absolute top-28 right-12 p-4;
  @apply bg-gray-50;
  @apply shadow-lg;
  @apply font-content;
  width: 400px;
  border-radius: 1rem;
}

.details-modal-header,
.details-modal-content,
.details-modal-footer {
  padding: 1rem;
}

.details-modal-footer > button {
  margin-right: 1rem;
}

.sidebar {
  height: 100%;
  overflow-y: scroll;
  text-align: left;
}

.sidebar-header {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.sidebar-header button {
  width: 100%;
  @apply font-heading text-gray-400;
}
.sidebar-header button.active {
  @apply text-blue-900;
  @apply border-b-4 rounded-sm border-blue-900;
}
.sidebar-header button:hover {
  @apply text-blue-900;
  transition: ease-in-out 0.1s;
}

.App {
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas: "header" "content" "footer";
}

main {
  height: 100%;
  display: grid;
  grid-template-columns: 500px 1fr;
  overflow: hidden;
}

.sidebar {
  height: 100%;
  overflow-y: scroll;
}

.map {
  height: 100%;
  width: 100%;
  padding: 2rem 2rem;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.wrapper {
  max-width: 95%;
  margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
  main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .map {
    padding: 0.75rem;
    border-radius: 0;
    @apply border-t border-gray-100;
  }

  .listings-label {
    padding: 1rem 0;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

