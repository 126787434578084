.btn-base
    @apply font-heading text-sm rounded-md shadow-sm py-2 px-3

    &.blue
        @apply bg-blue-700 text-white

        &:hover
            @apply bg-blue-600
            transition: ease-in-out 0.2s

    &.red
        @apply bg-red-700 text-white

        &:hover
            @apply bg-red-600
            transition: ease-in-out 0.2s