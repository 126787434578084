.listings-container
    margin: 0 2rem

.listings-label
    padding: 2rem 0
    @apply font-heading font-bold text-2xl text-blue-900

.listing
    display: flex
    flex-direction: column
    justify-content: space-between
    height: auto
    width: 100%
    border-radius: 1rem
    padding: 1rem
    margin-bottom: 0.5rem
    @apply bg-white border-2 border-gray-200 shadow-sm

.listing:hover
    transition: 0.2s
    @apply border-blue-900

.listing-header,
.listing-content,
.listing-footer
    margin: 0.5rem 0

.listing-address
    @apply font-heading font-medium text-base

.listing-date
    @apply font-content font-normal text-sm text-gray-500

.listing-time
    @apply font-content font-normal text-sm text-gray-500

.listing-description
    @apply font-content font-normal text-sm text-gray-500

.listing-viewed-label
    padding: 0.5rem
    @apply font-content font-normal text-sm
    