.details-modal
    @apply absolute top-28 right-12 p-4
    @apply bg-gray-50
    @apply shadow-lg
    @apply font-content 
    width: 400px
    border-radius: 1rem

.details-modal-header,
.details-modal-content,
.details-modal-footer
    padding: 1rem

.details-modal-footer > button
    margin-right: 1rem