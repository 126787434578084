.map
    @apply w-screen h-full

.map-marker
    @apply text-4xl
    @apply text-rose-700
    width: 2rem
    height: 3rem
    transform: translate(-50%, -75%)

    :hover
        @apply cursor-pointer
        @apply text-rose-600
        transition: .1s
